import { GetServerSideProps, NextPage } from 'next';
import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import LazyLoad from 'react-lazyload';
import { pageNextSEO } from 'src/utils/next-seo';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import _ from 'lodash';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
// import SectionUS from 'src/components/sections/section-event/section-us';
import SectionSpecialLive from 'src/components/sections/section-special-live';
// import { Media } from 'src/interface/media';
import { getIndexPage, initPropsPageIndex, TInitPropsIndexPage } from 'src/services/article.service';
import { decryptionClient } from 'src/services/member-auth/utils/decryption-payload';
import DynamicAds, { KEY_ADS_POSITION } from 'src/utils/ads/dynamic-ads';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
// import useResponsive from 'src/utils/devices';
import { dropSomeField } from 'src/utils/helper';
import useUser from 'src/utils/useUser';

const SectionOne = dynamic(import('src/components/sections/section-home/section-one'), { ssr: true });
const SectionTwo = dynamic(import('src/components/sections/section-home/section-two'), { ssr: true });
const SectionFour = dynamic(import('src/components/sections/section-home/section-four'), { ssr: false });
const SectionFive = dynamic(import('src/components/sections/section-home/section-five'), { ssr: false });
// const SectionLive = dynamic(import('src/section-events/events-010/live'), { ssr: false });
const SectionLandingPage = dynamic(import('src/section-events/events-008/section-landing-page'), { ssr: true });
const SectionEventOne = dynamic(import('src/section-events/event-001'), { ssr: false });
const SectionSix = dynamic(import('src/components/sections/section-home/section-six'), { ssr: false });
const SectionMember = dynamic(import('src/services/member-auth/components/section-member'), { ssr: true });
const SectionVideo = dynamic(import('src/section-events/events-011/section-video'), { ssr: false });
const SectionTag = dynamic(import('src/components/sections/section-home/section-tag'), { ssr: true });
const Layout = dynamic(import('src/components/layout'));

type Props = TInitPropsIndexPage;
const IndexPage: NextPage<Props> = props => {
  const data = useUser();
  const [sessionSevId, setSessionSevId] = useState<any>(null);
  const [sectionMember, setSectionMember] = useState<any>({});
  useEffect(() => {
    try {
      const checkUser = async () => {
        if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
          const response = await fetch(`/api/auth/metadata`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (response.ok) {
            const res = await response.json();
            localStorage.setItem('login-meta-data', res);
            setSectionMember(decryptionClient(res));
          }
        }
      };
      const loginMetaData = localStorage.getItem('login-meta-data');
      const decryptLoginMetaData = !_.isEmpty(loginMetaData) ? decryptionClient(loginMetaData) : {};
      const expireCondition: boolean = !_.isEmpty(loginMetaData) ? new Date(decryptLoginMetaData?.expire) < new Date() : true;

      if (!_.isEmpty(data) && !_.isEmpty(data?.user)) {
        setSessionSevId(decryptionClient(data?.user));
        _.isEmpty(loginMetaData) || expireCondition ? checkUser() : setSectionMember(decryptLoginMetaData);
      }
    } catch (err) {
      console.log('Error: Please enable website cookies. ' + err);
    }
  }, [data]);
  const { ads, sectionOne, sectionTwo, sectionThree, sectionFour, dataPopular, sectionLandingPage, sectionCover, dataSectionLive, dataSectionSpecialLive, sectionEventOne, soft, socialClip,
    dataSectionPAOElection } = props;
  // const responsive: Media = useResponsive();
  const router: NextRouter = useRouter();
  const [adsBox, setAdsBox] = useState(true);
  const handleLightBoxClick = () => {
    setAdsBox(false);
  };

  return (
    <>
      {/* -------------------------------- NEXT_SEO -------------------------------- */}
      <NextSeo {...pageNextSEO({ data: props })} />
      <SectionLandingPage data={sectionLandingPage} />
      <Layout sessionSevId={sessionSevId} sectionCover={sectionCover} >

        {!isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.LIGHTBOX} data={ads} handler={() => handleLightBoxClick()} adsBox={adsBox} />}
        {!isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />}
        <SectionSpecialLive
          data={dataSectionSpecialLive}
        />
        {!isEmpty(sectionEventOne) && <SectionEventOne />}
        {/* <SectionUS
          id='section-us'
          // ! DATALAYER
          eventDataLayer={{
            type: DATALAYER_TYPE.TRACK_POSITION,
            router: router?.pathname,
            section: 'section-us',
            position: 'ใต้:header'
          }}
        /> */}
        <SectionOne
          ads={ads}
          dataSectionLive={dataSectionLive}
          highlight1={sectionOne.highlight1}
          dataSectionPAOElection={dataSectionPAOElection}
          dataPopular={dataPopular}
          headerLatestNews={sectionOne.headerLatestNews}
          dataTags={sectionOne?.tags}
          soft={soft}
          // ! DATALAYER
          eventDataLayer={{
            type: DATALAYER_TYPE.TRACK_POSITION,
            router: router?.pathname,
            section: 'section-1',
            position: 'ใต้:header'
          }}
        />


        {!isEmpty(sectionMember) && <SectionMember data={{
          user: dropSomeField(sectionMember, ['menu', 'tags', 'articlesTags', 'articlesCategories'])?.user,
          menu: sectionMember?.menu,
          tags: sectionMember?.tags,
          articlesTags: sectionMember?.articlesTags,
          articlesCategories: sectionMember?.articlesCategories
        }} />}
        <SectionTwo
          soft={soft}
          block1={sectionOne.block1}
          block2={sectionOne.block2}
          block3={sectionOne.block3}
          // ! DATALAYER
          eventDataLayer={{
            type: DATALAYER_TYPE.TRACK_POSITION,
            router: router?.pathname,
            section: 'section-2',
            position: 'ใต้:section-1'
          }}
        />
        <SectionFour
          block6={sectionTwo.block3}
          block7={sectionThree.block1}
          // ! DATALAYER
          eventDataLayer={{
            type: DATALAYER_TYPE.TRACK_POSITION,
            router: router?.pathname
          }} />
        <LazyLoad offset={100}>
          <SectionFive
            block1={sectionThree.block2}
            block2={sectionThree.block3}
            // ! DATALAYER
            eventDataLayer={{
              type: DATALAYER_TYPE.TRACK_POSITION,
              router: router?.pathname,
              section: 'section-7',
              position: 'ใต้:section-6'
            }}
          />
          <SectionVideo
            // ! DATALAYER
            eventDataLayer={{
              type: DATALAYER_TYPE.TRACK_POSITION,
              router: router?.pathname,
              section: 'section-7',
              position: 'ใต้:section-6'
            }}
          />

          <SectionSix // ! DATALAYER
            block1={sectionFour.block2}
            block2={sectionFour.block3}
            socialClip={socialClip}
            eventDataLayer={{
              type: DATALAYER_TYPE.TRACK_POSITION,
              router: router?.pathname,
              section: 'section-12',
              position: 'ใต้:section-11'
            }}

          />
        </LazyLoad>
        {!isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.POSTARTICLE} data={ads} />}

        {!isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.AD1X1} data={ads} />}
        <SectionTag
          dataTags={sectionOne?.tags}
          eventDataLayer={{
            type: DATALAYER_TYPE.TRACK_POSITION,
            router: router?.pathname,
            section: 'section-1',
            position: 'ใต้:header'
          }}
        />
      </Layout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  let initProps: Props = {
    ...cloneDeep(initPropsPageIndex)
  };
  try {
    const initPropsIndexPage = await getIndexPage(ctx);

    initProps = {
      ...initProps,
      ...initPropsIndexPage
    };
  } catch (err: any) {
    initProps.errMessage = err.message;
    initProps.statusCode = err?.response?.status || 500;
  }
  return {
    props: initProps
  };
};
export default IndexPage;
